<template>
	<img
		v-if="src"
		:src="src"
		class="medal-image"
		:class="medalClasses"
		@click="$emit('click')"
	/>
	<div
		v-else
		class="medal-image"
		:class="medalClasses"
		@click="$emit('click')"
	></div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
		},
		size: {
			type: String,
			default: "base",
			validator: function (value) {
				return ["xs", "sm", "base", "lg", "xl"].indexOf(value) !== -1;
			},
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		medalClasses() {
			let size = `medal-${this.size}`;
			return { [size]: true, "skeleton-play": this.skeleton };
		},
	},
};
</script>

<style lang="scss" scoped>
.medal-image {
	border-radius: 50%;
	background-color: var(--bordercolor);
}

.medal-xs {
	width: 1.25rem; /* 20px */
	height: 1.25rem; /* 20px */
}

.medal-sm {
	width: 2.5rem; /* 40px */
	height: 2.5rem; /* 40px */
}

.medal-base {
	width: 3.75rem; /* 60px */
	height: 3.75rem; /* 60px */
}

.medal-lg {
	width: 5rem; /* 90px */
	height: 5rem; /* 90px */
}

.medal-xl {
	width: 6.25rem; /* 100px */
	height: 6.25rem; /* 100px */
}
</style>
